.merch-page {
  width: 100%;

  .merch-grid {
    box-sizing: border-box;
    width: 100%;
    padding: 0 2.5%;
    padding-bottom: 200px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 960px;
    margin: auto;

    a {
      text-decoration: none;
    }
  }

  .merch-item {
    font-size: 16px;
    flex-basis: calc(50% - 1.25vw);
    max-width: 200px;
    margin-bottom: 27px;
    cursor: pointer;
  }
  .merch-item-image {
    width: 100%;
    margin-bottom: 1em;
  }

  .merch-item-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 11px;
    font-weight: 500;
    margin: 0 3px;
  }

  .merch-item-name {
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    margin-bottom: 11px;
    text-decoration: none;
  }
}

.merch-detail-page {
  text-align: center;
  min-height: var(--app-height);

  .merch-item {
    width: 90%;
    max-width: 400px;
    margin: auto;
  }

  .item-image {
    width: 90%;
    margin: auto;
  }

  .item-description {
    font-weight: 400;
    width: 100%;
    max-width: 460px;
    margin: 1em auto;
    font-size: 16px;
  }
  .merch-item-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 14px;
    margin: 1em auto;
    max-width: 90%;
  }

  .line {
    margin: 36px auto;
    width: 100px;
  }

  label {
    font-size: 14px;
    text-align: left;
    display: block;
    line-height: 1.5em;
    text-indent: 7px;
  }
  input,
  select {
    border-color: var(--dark-grey);
    display: block;
    line-height: 2em;
    -webkit-appearance: none;
    border: 2px solid #1c1c1c;
    border-radius: 2em;
    width: 100%;
    margin-bottom: 1em;
    font-size: 18px;
    font-family: 'Inter', 'Helvetica Neue', sans-serif;
    padding-left: 1em;
    font-weight: 600;
    outline: none;
  }

  form {
    margin-bottom: 5em;
  }

  select[disabled] {
    color: #aaa;
  }

  input:focus {
    border-color: var(--blue);
    outline: none;
  }
}

.merch-shipping-page {
  p {
    text-align: left;
    text-indent: 7px;
  }
  .form-section-heading {
    margin-top: 50px;
    & + p {
      margin-top: 0;
    }
  }
  input[type='submit'] {
    opacity: 0;
    height: 0;
  }
}
